<template>
  <b-card>
    <h3>Lessons documentation</h3>
    <!-- Rating block -->
    <div class="p-1">
      <h4>1. Lessons</h4>
      <b-img
        src="https://www.goskate.com/wp-content/uploads/2021/12/lessonsfirsttable.png"
        fluid
        alt="Fluid image"
        class="pb-2"
      />
      <h5>
        In the Lessons tab, the first table shows you information about your lessons. Which you currently have active.<br>
        1. You see the name of your client from whom you are conducting a lesson.<br>
        2. You see the creation date of your lessons.<br>
        3. Progress is the status of your lessons at the moment.
      </h5>
      <hr>
    </div>
    <!--End Rating block -->
  </b-card>
</template>
<script>
import { BCard, BImg } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImg,
  },
  data() {
    return {
      data: {},
    }
  },
}
</script>
<style lang="scss">
  hr {
    border: 0;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }
</style>
