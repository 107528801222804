<template>
  <b-card>
    <h3>Dashboard documentation</h3>
    <!-- Rating block -->
    <div class="p-1">
      <h4>1. Rating</h4>
      <b-img
        src="https://www.goskate.com/wp-content/uploads/2021/12/rating.png"
        fluid
        alt="Fluid image"
        class="pb-2"
      />
      <h5>
        After completing each lesson, you are awarded points, which are presented on the Dashboard page as Rating.
      </h5>
      <hr>
    </div>
    <!--End Rating block -->
    <!-- Completed lessons block -->
    <div class="p-1">
      <h4>2. Completed lessons</h4>
      <b-img
        src="https://www.goskate.com/wp-content/uploads/2021/12/Completedlessons.png"
        fluid
        alt="Fluid image"
        class="pb-2"
      />
      <h5>
        The Dashboard page contains information about the total number of lessons you have completed. The lesson is considered completed after you have confirmed its completion.
      </h5>
      <hr>
    </div>
    <!--End Completed lessons block -->
    <!-- Invitations block -->
    <div class="p-1">
      <h4>3. Invitations lessons</h4>
      <b-img
        src="https://www.goskate.com/wp-content/uploads/2021/12/invitations.png"
        fluid
        alt="Fluid image"
        class="pb-2"
      />
      <h5>
        The invitations field displays all the job invitations that you have received.<br> 🟩Green - invitations that you have accepted for work.<br> 🟥Red - invitations that you have not accepted.<br> ⬛Gray is the total number of invitations.
      </h5>
      <hr>
    </div>
    <!--End Invitations block -->
    <!-- Next Lesson block -->
    <div class="p-1">
      <h4>4. Next lesson</h4>
      <b-img
        src="https://www.goskate.com/wp-content/uploads/2021/12/next_lesson.png"
        fluid
        alt="Fluid image"
        class="pb-2"
      />
      <h5>
        Also on the main screen you have a calendar in which your next lessons are displayed. Which have been confirmed by you.
      </h5>
      <hr>
    </div>
    <!--Next Lesson block -->
    <!-- Next Lesson block -->
    <div class="p-1">
      <h4>5. Money received</h4>
      <b-img
        src="https://www.goskate.com/wp-content/uploads/2021/12/money.png"
        fluid
        alt="Fluid image"
        class="pb-2"
      />
      <h5>
        Money received shows you how much money you have earned during the period of working with our service, depends on the number of lessons you have completed.
      </h5>
      <hr>
    </div>
    <!--Next Lesson block -->
  </b-card>
</template>
<script>
import { BCard, BImg } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImg,
  },
  data() {
    return {
      data: {},
    }
  },
}
</script>
<style lang="scss">
  hr {
    border: 0;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }
</style>
