<template>
  <div>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <!-- Dashboard documentation -->
      <b-tab active>
        <template #title>
          <span class="font-weight-bold">Dashboard</span>
        </template>

        <documentation-dashboard
          v-if="data"
          :general-data="data"
        />
      </b-tab>
      <!-- End Dashboard documentation -->
      <!-- Lessons documentation -->
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Lessons</span>
        </template>

        <documentation-lessons
          v-if="data"
          :general-data="data"
        />
      </b-tab>
      <!-- End Lessons documentation -->
      <!-- Invitations documentation -->
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Invitations</span>
        </template>

        <documentation-invitations
          v-if="data"
          :general-data="data"
        />
      </b-tab>
      <!-- End Invitations documentation -->
      <!-- Payments documentation -->
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Payments</span>
        </template>

        <documentation-payments
          v-if="data"
          :general-data="data"
        />
      </b-tab>
      <!-- End Payments documentation -->
      <!-- Settings documentation -->
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Settings</span>
        </template>

        <documentation-settings
          v-if="data"
          :general-data="data"
        />
      </b-tab>
      <!-- End Settings documentation -->
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import DocumentationLessons from './DocumentationLessons.vue'
import DocumentationInvitations from './DocumentationInvitations.vue'
import DocumentationPayments from './DocumentationPayments.vue'
import DocumentationSettings from './DocumentationSettings.vue'
import DocumentationDashboard from './DocumentationDashboard.vue'

export default {
  components: {
    BTabs,
    BTab,
    DocumentationLessons,
    DocumentationInvitations,
    DocumentationPayments,
    DocumentationSettings,
    DocumentationDashboard,
  },
  data() {
    return {
      data: {},
    }
  },
}
</script>
